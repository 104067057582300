.privacy-policy p {
  line-height: 2;
}

.privacy-policy li {
  line-height: 2;
}

.privacy-policy div {
  margin-top: 5px;
}
