.home-text {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

@keyframes rotate-blob {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.blob-effect {
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  position: relative;
  border-radius: 50%;
}

.blob-effect span:nth-child(1) {
  position: absolute;
  opacity: 0.3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #d7e8b6;
  border-radius: 36% 64% 44% 56% / 58% 40% 60% 42%;
  animation: rotate-blob 15s linear infinite;
}

.blob-effect span:nth-child(2) {
  position: absolute;
  opacity: 0.3;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #d7e8b6;
  border-radius: 36% 64% 44% 56% / 58% 40% 60% 42%;
  animation: rotate-blob 13s linear infinite;
}

.blob-effect span:nth-child(3) {
  position: absolute;
  opacity: 0.3;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #d7e8b6;
  border-radius: 36% 64% 44% 56% / 58% 40% 60% 42%;
  animation: rotate-blob 11s linear infinite;
}

.blob-effect span:nth-child(4) {
  position: absolute;
  opacity: 0.3;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #d7e8b6;
  border-radius: 36% 64% 44% 56% / 58% 40% 60% 42%;
  animation: rotate-blob 9s linear infinite;
}

.main {
  background-image: url("../../images/LandingPageImage.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.home-products {
  display: grid;
  grid-template-columns: 150px 150px 150px 150px;
  grid-template-rows: auto auto;
  gap: 10px;
}

.mountain {
  transform: rotateY(-180deg);
}

@media screen and (min-width: 760px) {
  .home-products {
    grid-template-columns: 200px 200px 200px 200px;
  }
}
