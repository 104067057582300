@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@keyframes appear {
  from {
    opacity: 0;
    scale: 0.5;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}

.boxes {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-items: center;
}

.small-product-boxes {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(64px, 1fr));
  justify-items: center;
}

.medium-product-boxes {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  justify-items: center;
  gap: 10px;
}

.brown-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  height: 100%;
  padding: 0px 15px 0px 15px;
  font-weight: 400;
  border-radius: 5px;
  color: #e5d3b3;
  transition: 300ms;
}

.brown-button:hover {
  cursor: pointer;
  background-color: #e5d3b3;
  color: #402c1f;
}

.green-button1 {
  font-size: 18px;
  font-weight: 400;
  padding: 10px 20px;
  border: 2px solid #75975e;
  margin: 5px;
  border-radius: 5px;
  background-color: #75975e;
  color: white;
  transition: 400ms;
}

.green-button1:hover {
  cursor: pointer;
  border: 2px solid #607c4d;
  background-color: #607c4d;
  color: white;
}

.green-button2 {
  font-size: 16px;
  font-weight: 400;
  padding: 10px 20px;
  border: 2px solid #75975e;
  margin: 5px;
  border-radius: 5px;
  color: #75975e;
  transition: 400ms;
}

.green-button2:hover {
  cursor: pointer;
  border: 2px solid #607c4d;
  background-color: #607c4d;
  color: white;
}

.red-button {
  font-size: 16px;
  font-weight: 400;
  padding: 10px 20px;
  border: 2px solid #ad3131;
  margin: 5px;
  border-radius: 5px;
  background-color: #ad3131;
  color: white;
  transition: 400ms;
}

.red-button:hover {
  cursor: pointer;
  border: 2px solid #8e2b2b;
  background-color: #8e2b2b;
  color: white;
}

.red-button2 {
  font-size: 16px;
  font-weight: 400;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  color: white;
  background-color: #ad3131;
  transition: 400ms;
}

.red-button2:hover {
  cursor: pointer;
  background-color: #8e2b2b;
  color: white;
}

.circle-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  width: 32px;
  height: 32px;
  border: 2px solid #38271a;
  margin: 5px;
  border-radius: 50%;
  background-color: #38271a;
  color: #e5d3b3;
  transition: 400ms;
}

.circle-button:hover {
  cursor: pointer;
}

.shop-button {
  transform: translateY(-70px);
  font-size: 14px;
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: solid 2px #d7e8b6;
  padding: 0px 15px 0px 15px;
  height: 45px;
  font-weight: 400;
  border-radius: 5px;
  color: #e5d3b3;
  transition: 300ms;
  cursor: pointer;
}

.shop-button::before {
  font-size: 14px;
  background-color: #d7e8b6;
  border: solid 2px #d7e8b6;
  border-radius: 0 50% 50% 0;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  transition: 300ms;
}

.shop-button:hover {
  color: #402c1f;
}

.shop-button:hover::before {
  text-wrap: nowrap;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  content: "Shop Now";
  color: #402c1f;
  width: 100%;
  border-radius: 0;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes back-and-forth {
  0% {
    opacity: 1;
    transform: translateX(0px);
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0;
    transform: translateX(50px);
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateX(0px);
  }
}

.spinner {
  animation: spin 0.5s linear infinite;
}

.truck {
  animation: back-and-forth 2s linear infinite;
}

.active {
  color: #e5d3b3;
}

.active > p {
  text-decoration: underline;
  text-underline-offset: 5px;
  color: black;
}

.text {
  font-size: 14px;
}

@media screen and (min-width: 450px) {
  .shop-button {
    font-size: 18px;
    padding: 0px 20px 0px 20px;
    height: 50px;
  }

  .shop-button::before {
    font-size: 18px;
  }

  .medium-product-boxes {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .text {
    font-size: 16px;
  }
}

@media screen and (min-width: 760px) {
  .medium-product-boxes {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  }
}
