.sidebar {
  left: -1100px;
  transition: 0.5s;
  z-index: 20;
}

#activate-sidebar:checked ~ #overlay {
  width: 100vw;
  height: 100vh;
  position: relative;
  right: 0;
  top: 0;
}

#activate-sidebar:checked ~ .sidebar {
  left: 0px;
}

.nav-bar {
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
}

.nav-icons {
  font-size: 14px;
}
.nav-icons-text {
  font-size: 8px;
}

@media screen and (min-width: 330px) {
  .nav-icons {
    font-size: 20px;
  }
  .nav-icons-text {
    font-size: 12px;
  }
}

@media screen and (min-width: 500px) {
  .nav-icons {
    font-size: 24px;
  }
  .nav-icons-text {
    font-size: 16px;
  }
}
