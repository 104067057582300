.thumbnail {
  width: 30px;
  height: 50px;
  object-fit: cover;
  border-radius: 15%;
  margin: 0px 2px 0px 2px;
  opacity: 0.5;
  transition: 300ms;
}

.activeImage {
  margin: 0px 4px 0px 4px;
  width: 50px;
  opacity: 1;
}
